import PKG_JSON from "../../../package.json"

const K_DOMAIN = getConfig().domain;
const K_NAME = getConfig().name;
const K_QINIU_DOMAIN = 'https://res.novobar.com/';

const K_VERSION = PKG_JSON.version
const K_PROJECT_NAME = PKG_JSON.name

const K_QINIU_IMAGE_SMALL_END_200_SUFFIX = '?imageMogr2/thumbnail/200x200'
const K_QINIU_IMAGE_SMALL_END_100_PX_SUFFIX = '?imageMogr2/thumbnail/100x'

const K_PREVIEW_DOMAIN = getConfig().preview_domain;

function getConfig() {
    let url = {}
    let hostname = window.location.hostname;
    let origin = window.location.origin;
    if (hostname.indexOf('192') > -1 || hostname.indexOf('localhost') > -1) {
        if (hostname.indexOf("192.168.192.105") > -1) {
            url = {
                domain: 'http://192.168.192.105:22461',//22461后台服务端口
                load: 'http://192.168.192.105:22461',//22461后台服务端口
                name: '开发',
                preview_domain: 'http://192.168.192.105:22471'//22471前端端口
            };
        } else {
            url = {
                domain: 'http://192.168.192.157:22461',//22461后台服务端口
                load: 'http://192.168.192.157:22461',//22461后台服务端口
                name: '测试',
                preview_domain: 'http://192.168.192.157:22471'//22471前端端口
            };
        }
    } else {
        url = {
            domain: origin,
            load: origin,
            name: '总站',
            preview_domain: 'https://www.novobar.com'
        };
    }

    document.title = `NOVOBAR 后台管理系统(${url.name})`;
    return url
}

export {
    K_DOMAIN,
    K_QINIU_IMAGE_SMALL_END_200_SUFFIX,
    K_QINIU_IMAGE_SMALL_END_100_PX_SUFFIX,
    K_NAME,
    K_QINIU_DOMAIN,
    K_VERSION,
    K_PROJECT_NAME,
    K_PREVIEW_DOMAIN
}